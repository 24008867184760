// check if a password meets the minimum requirements:
// - 10-30 characters
// - 1 number
// - 1 upper case letter
// - 1 special character
function isValidPassword(password) {
  // password does not meet requirements (10-30 char, 1 num, 1 upper, 1 special)
  const passFormat = /^(?=.*\d)(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{10,30}$/;

  return password.match(passFormat) ? true : false;
}

export { isValidPassword };
