// Third-party imports.
import React from "react";
import PropTypes from "prop-types";
import jQuery from "jquery";
import { StyledLink } from "baseui/link";
import styled from "styled-components";
import { ParagraphMedium, ParagraphSmall } from "baseui/typography";

// Our imports.
import { CenteredLoadingSpinner } from "../../components/base_ui/progress_and_validation/spinner/centered_loading_spinner";
import { Button } from "../../components/base_ui/inputs/button";
import FormControl from "../../components/base_ui/inputs/form_control";
import Input from "../../components/base_ui/inputs/input";

import ExistingUserAdditionalInfo from "./additional_info";
import ExistingUserResetPassword from "./reset_password";
import "./sign_in_form.css";
import { SilentH1 } from "../../../utils/headers";

const StyledContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizing.scale600};
`;

const StyledParagraph = styled(ParagraphSmall)`
  color: ${({ theme }) => theme.colors.contentTertiary};
`;

const StyledInputsContainer = styled.div`
  label {
    font-family: ${({ theme }) => theme.typography.LabelMedium.fontFamily};
    font-weight: ${({ theme }) => theme.typography.LabelMedium.fontWeight};
    font-size: ${({ theme }) => theme.typography.LabelMedium.fontSize};
    line-height: ${({ theme }) => theme.typography.LabelMedium.lineHeight};
    text-transform: none;
  }
`;

// This component relies on a parent component to keep the email and password state for it.
class ExistingUserSignInForm extends React.Component {
  static propTypes = {
    roleToRegisterFor: PropTypes.string.isRequired, // "Mentor" | "Student"
    programId: PropTypes.string.isRequired,

    // What do we already know about this existing user? We should either have
    // the email that a user entered and we validated as an existing account,
    // or if we instead identified them based on first/last name, the domain
    // of the email they already have registered (we don't know 100% if this is actually
    // the current user or not yet, so we don't have the full email), and whether or not
    // the already registered user has a valid phone #
    knownUserInfo: PropTypes.oneOfType([
      PropTypes.shape({
        emailDomain: PropTypes.string.isRequired,
        hasValidPhone: PropTypes.bool.isRequired,
        id: PropTypes.number.isRequired,
      }),
      PropTypes.shape({
        email: PropTypes.string.isRequired,
      }),
    ]),
    inputUserInfo: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,

    // What URL do we hit to sign up and register this person?
    signInPath: PropTypes.string.isRequired,
    registerPath: PropTypes.string.isRequired,
    sendPasswordResetEmailPath: PropTypes.string.isRequired,

    csrfToken: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      inputEmail: props.knownUserInfo.email ? props.knownUserInfo.email : "",
      inputPassword: "",

      // For tracking when we should display a spinner to let the user know we're doing something on
      // their behalf.
      processingSubmission: false,

      // Show/hide for the "additional info" box at the bottom of the screen.
      displayingAdditionalInfo: false,

      // We may display errors to the user if information they provided is invalid.
      errors: [],

      // If the User has signaled that they forgot their password
      forgotPassword: false,
    };
  }

  render() {
    let existingUserAdditionalInfo;
    if (this.state.displayingAdditionalInfo) {
      existingUserAdditionalInfo = <ExistingUserAdditionalInfo />;
    }

    let headerElement;
    if (this.props.knownUserInfo.emailDomain) {
      headerElement = (
        <StyledContainer>
          <SilentH1>
            Please log in to your existing account, using your{" "}
            <strong>{this.props.knownUserInfo.emailDomain}</strong> email.
          </SilentH1>
        </StyledContainer>
      );
    } else {
      headerElement = (
        <StyledContainer>
          <SilentH1>You already have an account with us — nice!</SilentH1>
          <ParagraphMedium>
            Enter your password to log in and register for this year's program.
          </ParagraphMedium>
        </StyledContainer>
      );
    }

    return (
      <div className="existing-user-sign-in-form">
        {this.state.forgotPassword ? (
          <ExistingUserResetPassword
            knownUserInfo={this.props.knownUserInfo}
            sendPasswordResetEmailPath={this.props.sendPasswordResetEmailPath}
            programId={this.props.programId}
            roleToRegisterFor={this.props.roleToRegisterFor}
            inputUserInfo={this.props.inputUserInfo}
          />
        ) : (
          <React.Fragment>
            <div className="text-align-center">{headerElement}</div>
            {this.renderFormElement()}
            {this.renderFormErrorsElement()}
            <div className="text-align-center vertical-spacing-top two-rems">
              <StyledLink
                aria-label="Reset your password"
                className="forgot-your-password"
                tabIndex="0"
                onClick={() =>
                  this.setState((prevState) => ({ ...prevState, forgotPassword: true }))
                }
              >
                Forgot password?
              </StyledLink>
            </div>
          </React.Fragment>
        )}

        <div className="additional-info-container">
          <div className="additional-info-link-container">
            <StyledParagraph>
              Not sure what's going on?{" "}
              <StyledLink
                tabIndex="0"
                onClick={() =>
                  this.setState({ displayingAdditionalInfo: !this.state.displayingAdditionalInfo })
                }
              >
                Learn More
              </StyledLink>
            </StyledParagraph>
          </div>
          {existingUserAdditionalInfo}
        </div>
      </div>
    );
  }

  renderFormErrorsElement() {
    if (this.state.errors.length === 0) {
      return null;
    }

    const errorItems = this.state.errors.map((error) => (
      <div key={error} className="error-item">
        {error}
      </div>
    ));

    return <div className="form-errors">{errorItems}</div>;
  }

  renderFormElement() {
    // We aren't using the submit capabilities of the <form> element -- that is handled by Ajax.
    return (
      <form
        name="existing_user_sign_in_form"
        className="vertical-spacing two-rems text-align-center"
        onSubmit={this.onSubmitSignInForm}
      >
        {this.renderFormItems()}
        {this.state.processingSubmission && <CenteredLoadingSpinner />}
        <Button className="signinButton" disabled={!this.allowSubmit()}>
          Done
        </Button>
      </form>
    );
  }

  renderFormItems() {
    return (
      <StyledInputsContainer>
        <div>
          <FormControl label="Email">
            <Input
              aria-label="email"
              name="email"
              value={this.state.inputEmail}
              placeholder="Enter your email"
              onChange={({ currentTarget: { value: updatedEmail } }) =>
                this.setState((prevState) => ({
                  ...prevState,
                  inputEmail: updatedEmail,
                  errors: [],
                }))
              }
            />
          </FormControl>
        </div>
        <div>
          <FormControl label="Password">
            <Input
              aria-label="password"
              type="password"
              name="password"
              value={this.state.inputPassword}
              placeholder="Enter your password"
              onChange={({ currentTarget: { value: updatedPassword } }) =>
                this.setState((prevState) => ({
                  ...prevState,
                  inputPassword: updatedPassword,
                  errors: [],
                }))
              }
            />
          </FormControl>
        </div>
      </StyledInputsContainer>
    );
  }

  /**
   * @return {Boolean} True if we allow the user to click the submit button, false otherwise.
   */
  allowSubmit() {
    return this.state.inputEmail && this.state.inputPassword;
  }

  onSubmitSignInForm = async (event) => {
    // We don't actually want the form to be submitted. Instead we take data from the form and
    // submit it using AJAX.
    event.preventDefault();

    // Start the button spinning so the user knows we're doing something.
    this.setState({ processingSubmission: true });

    // This is a two-request process: first we make a POST request to sign the user in, then we make
    // a POST request to register them for the program (if they are not registered already).
    try {
      // Note we are binding the return value of jQuery.post and then awaiting, not awaiting
      // directly. This is because we need to access the CSRF token in the first response so we can
      // use it in the second response. Rails generates a new CSRF token in between the first
      // request and the second.

      // See http://api.jquery.com/jQuery.ajax/#jqXHR for documentation about the jqXHR object.
      const signInjqXHR = jQuery.post({
        url: this.props.signInPath,
        headers: { "X-CSRF-Token": this.props.csrfToken },
        data: {
          api_user: {
            email: this.state.inputEmail,
            password: this.state.inputPassword,
          },
        },
      });
      await signInjqXHR;
      const newCSRFToken = signInjqXHR.getResponseHeader("X-CSRF-Token");

      await jQuery.post({
        url: this.props.registerPath,
        headers: { "X-CSRF-Token": newCSRFToken },
        data: {
          role: this.props.roleToRegisterFor,
          program_id: this.props.programId,
        },
      });
    } catch (response) {
      // Display the error in the form.
      this.setState({ errors: [response.responseText] });
      return;
    } finally {
      this.setState({ processingSubmission: false });
    }

    // We're now signed in and registered for the new program, put them on the pipe.
    window.location.reload();
  };
}

export default ExistingUserSignInForm;
