import styled from "styled-components";

/**
 * This component allows us to insert a Header 1 with the styling of
 * a Header 3 in the sign in / sign up flow pages.
 * @deprecated
 */
export const H1asH3 = styled.h1`
  font-size: 1.375em;
  font-weight: normal;
  letter-spacing: 0.0025em;
  line-height: 1.5em;
  margin-top: 0em;
  margin-bottom: 0.1em;
`;

/**
 * This component allows us to insert a Header 1 with the styling without
 * any visual indication that the text is different than the rest of a paragraph
 * in the sign in / sign up flow pages.
 * @deprecated
 */
export const SilentH1 = styled.h1<{ bold?: boolean }>`
  display: inline;
  font-size: 1em;
  font-weight: ${({ bold }: { bold?: boolean }) => (bold ? "bold" : "normal")};
  font-style: normal;
`;
