import React from "react";
import PropTypes from "prop-types";
import jQuery from "jquery";
import { ParagraphMedium } from "baseui/typography";
import { CenteredLoadingSpinner } from "../../../components/base_ui/progress_and_validation/spinner/centered_loading_spinner";
import { Button } from "../../../components/base_ui/inputs/button";
import FormControl from "../../../components/base_ui/inputs/form_control";
import Input from "../../../components/base_ui/inputs/input";

const EmailFormControlOverrides = {
  Label: {
    style: ({ $theme }) => ({
      fontFamily: $theme.typography.LabelMedium.fontFamily,
      fontWeight: $theme.typography.LabelMedium.fontWeight,
      fontSize: $theme.typography.LabelMedium.fontSize,
      lineHeight: $theme.typography.LabelMedium.lineHeight,
      textTransform: "none",
    }),
  },
};

function ResetEmailConfirmation({ email }) {
  return (
    <div className="text-align-center vertical-spacing-top two-rems">
      <ParagraphMedium>
        No worries! We sent an email to <strong>{email}</strong> that will let you log in and reset
        your password. If you didn't receive it, contact help@mentorcollective.org.
      </ParagraphMedium>
    </div>
  );
}
ResetEmailConfirmation.propTypes = {
  email: PropTypes.string.isRequired,
};
function ResetWithKnownEmail({ email, onClickSend, errors, emailInFlight, emailSent }) {
  if (emailSent) {
    return <ResetEmailConfirmation email={email} />;
  }
  return (
    <div className="text-align-center">
      <ParagraphMedium>
        Would you like to send a password reset email to <strong>{email}</strong>?
      </ParagraphMedium>
      {errors > 0 && (
        <div className="form-errors">
          {errors.map((error) => (
            <div key={error} className="error-item">
              {error}
            </div>
          ))}
        </div>
      )}

      <div className="vertical-spacing-top two-rems">
        {emailInFlight && <CenteredLoadingSpinner />}
        <Button onClick={() => onClickSend(email)}>Send Email</Button>
      </div>
    </div>
  );
}
ResetWithKnownEmail.propTypes = {
  email: PropTypes.string.isRequired,
  onClickSend: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  emailInFlight: PropTypes.bool.isRequired,
  emailSent: PropTypes.bool.isRequired,
};

class ResetWithUnknownEmail extends React.Component {
  static propTypes = {
    emailDomain: PropTypes.string.isRequired,
    onClickSend: PropTypes.func.isRequired,
    errors: PropTypes.array.isRequired,
    emailInFlight: PropTypes.bool.isRequired,
    emailSent: PropTypes.bool.isRequired,
    onClickBack: PropTypes.func.isRequired,
  };

  state = {
    inputEmail: "",
  };

  render() {
    if (this.props.emailSent) {
      return <ResetEmailConfirmation email={this.state.inputEmail} />;
    }
    return (
      <div className="text-align-center">
        <div className="text-align-center vertical-spacing-top two-rems">
          <ParagraphMedium>
            Just enter your existing <strong>{this.props.emailDomain}</strong> email, and we'll send
            you a link to reset your password.
          </ParagraphMedium>
          <div className="vertical-spacing-top two-rems">
            <FormControl label="Email" overrides={EmailFormControlOverrides}>
              <Input
                value={this.state.inputEmail}
                placeholder="Email"
                onChange={({ currentTarget: { value: updatedEmail } }) =>
                  this.setState((prevState) => ({ ...prevState, inputEmail: updatedEmail }))
                }
              />
            </FormControl>
          </div>
          {this.props.errors.length > 0 && (
            <div className="form-errors">
              {this.props.errors.map((error) => (
                <div key={error} className="error-item">
                  {error}
                </div>
              ))}
            </div>
          )}
          <div className="vertical-spacing-top two-rems">
            {this.props.emailInFlight && <CenteredLoadingSpinner />}
            <Button
              disabled={this.state.inputEmail.length < 1}
              onClick={() => this.props.onClickSend(this.state.inputEmail)}
            >
              Send Email
            </Button>
          </div>
          <div className="vertical-spacing-top two-rems">
            <Button onClick={() => this.props.onClickBack()}>Back</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default class ResetWithEmail extends React.Component {
  static propTypes = {
    // What do we already know about this existing user? We should either have
    // the email that a user entered and we validated as an existing account,
    // or if we instead identified them based on first/last name, the domain
    // of the email they already have registered (we don't know 100% if this is actually
    // the current user or not yet, so we don't have the full email), and whether or not
    // the already registered user has a valid phone #
    knownUserInfo: PropTypes.oneOfType([
      PropTypes.shape({
        emailDomain: PropTypes.string.isRequired,
        hasValidPhone: PropTypes.bool.isRequired,
        id: PropTypes.number.isRequired,
      }),
      PropTypes.shape({
        email: PropTypes.string.isRequired,
      }),
    ]),
    sendPasswordResetEmailPath: PropTypes.string.isRequired,
    programId: PropTypes.string.isRequired,
    roleToRegisterFor: PropTypes.string.isRequired,
    onClickBack: PropTypes.func.isRequired,
  };

  state = {
    errors: [],
    resetInFlight: false,
    emailSent: false,
  };

  isEmailValidForKnownUser = async (email) => {
    try {
      const result = await jQuery.get({
        url: "/api/users/check_valid_email",
        data: {
          user_id: this.props.knownUserInfo.id,
          email_to_validate: email,
        },
      });
      return result.is_valid;
    } catch (response) {
      this.setState((prevState) => ({
        ...prevState,
        errors: [
          "There was a problem validating your input email address. Please try again or refresh the page",
        ],
      }));
      return false;
    }
  };

  sendResetPasswordEmail = async (email) => {
    this.setState((prevState) => ({ ...prevState, resetInFlight: true }));
    try {
      await jQuery.post({
        url: this.props.sendPasswordResetEmailPath,
        data: {
          email,
          program_id: this.props.programId,
          role: this.props.roleToRegisterFor,
        },
      });
      this.setState((prevState) => ({ ...prevState, emailSent: true }));
    } catch (response) {
      // Display the error in the form.
      if (response.responseJSON) {
        this.setState((prevState) => ({ ...prevState, errors: response.responseJSON.errors }));
      } else if (response.status === 404) {
        this.setState((prevState) => ({
          ...prevState,
          errors: ["Couldn't find any user with that email address."],
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          errors: [`Error: HTTP ${response.status}. Please try again or refresh the page.`],
        }));
      }
      return;
    } finally {
      this.setState((prevState) => ({ ...prevState, resetInFlight: false }));
    }
  };

  render() {
    return this.props.knownUserInfo.email ? (
      <ResetWithKnownEmail
        email={this.props.knownUserInfo.email}
        errors={this.state.errors}
        onClickSend={this.sendResetPasswordEmail}
        emailInFlight={this.state.resetInFlight}
        emailSent={this.state.emailSent}
      />
    ) : (
      <ResetWithUnknownEmail
        emailDomain={this.props.knownUserInfo.emailDomain}
        errors={this.state.errors}
        onClickSend={async (email) => {
          const isEmailValid = await this.isEmailValidForKnownUser(email);
          if (isEmailValid) {
            await this.sendResetPasswordEmail(email);
          } else {
            this.setState((prevState) => ({
              ...prevState,
              errors: [
                `The email you entered doesn't match your existing email we have on file. Please enter your existing ${this.props.knownUserInfo.emailDomain} email and try again.`,
              ],
            }));
          }
        }}
        emailInFlight={this.state.resetInFlight}
        emailSent={this.state.emailSent}
        onClickBack={this.props.onClickBack}
      />
    );
  }
}
