// Third-party imports.
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import jQuery from "jquery";
import _ from "lodash";
import "./sign_up_flow.css";

// Our imports.
import * as Utils from "../../utils/utils";
import { isValidPassword } from "../../utils/password_utils";
import { LoginPage as LoginBackground } from "../login";
import LegalTermsAgreement from "../legal_terms_agreement";
import EuCitizenCheckbox from "./eu_citizen_checkbox";
import { CenteredLoadingSpinner } from "../components/base_ui/progress_and_validation/spinner/centered_loading_spinner";
import { Button } from "../components/base_ui/inputs/button";
import FormControl from "../components/base_ui/inputs/form_control";
import Input from "../components/base_ui/inputs/input";
import SignUpAdditionalInfo from "./sign_up_additional_info";
import ExistingUserSignInForm from "./existing_user/sign_in_form";
import DisambiguateDuringSignUp from "./disambiguate_during_sign_up";
import styled, { ThemeProvider } from "styled-components";
import {
  StyledCard,
  StyledHeading,
  StyledParagraphMedium,
} from "../shared/login_and_sign_up/styled";
import SsoButton from "../sso_button";
import deprecatedTheme from "../styling/deprecated_theme";
import { LabelTranslator } from "../../utils/label_utils";
import { StyledLink } from "baseui/link";
import { ParagraphSmall } from "baseui/typography";
import { Provider as StyletronProvider } from "styletron-react";
import { BaseProvider } from "baseui";
import baseuiTheme from "../../components/styling/baseui_theme";
import { Client as Styletron } from "styletron-engine-atomic";
import ProgramSchoolLogoBlock from "../shared/login_and_sign_up/program_school_logo_block";
import { Enterprise } from "@carbon/icons-react";
import { emailRegex } from "../../utils/string_utils";

const engine = new Styletron();

const StyledParagraphSmall = styled(ParagraphSmall)`
  color: ${({ theme }) => theme.colors.contentTertiary};
  margin-top: 0px;
  margin-bottom: 0px;
`;

const CheckboxContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizing.scale800};
  margin-bottom: ${({ theme }) => theme.sizing.scale800};
`;

const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizing.scale800};
  margin-bottom: ${({ theme }) => theme.sizing.scale1000};
`;

// States.
const signUpForm = "signUpForm";
const existingUserSignInForm = "existingUserSignInForm";
const disambiguating = "disambiguating";

// Disambiguation outcomes.
const yesThatsMe = "yes-thats-me";
const noThatsNotMe = "no-thats-not-me";
class SignUpFlow extends React.Component {
  static propTypes = {
    userId: PropTypes.string,
    userFullName: PropTypes.string,
    activeSsoTokenForSchool: PropTypes.bool,
    role: PropTypes.string.isRequired, // e.g. 'Student' or 'Mentor'
    programData: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      logo: PropTypes.string,
      shortName: PropTypes.string.isRequired,
      schoolName: PropTypes.string.isRequired,
      schoolShortName: PropTypes.string.isRequired,
      programInfoUrl: PropTypes.string,
      schoolId: PropTypes.string.isRequired,
      schoolLogo: PropTypes.string,
      programCustomLabel: PropTypes.object.isRequired,
      higherEdSegment: PropTypes.string.isRequired,
    }).isRequired,
    isSsoEnabled: PropTypes.bool.isRequired,
    ssoConnection: PropTypes.string,
    ssoBrand: PropTypes.string,

    // What URL to we hit to check if the user is signed up?
    checkIfUserSignedUpPath: PropTypes.string.isRequired,
    // What URL do we hit to determine if the user who's signing up is already a contact?
    detectExistingContactPath: PropTypes.string.isRequired,
    // What URL do we hit to determine if the user who's signing up might be a duplicate?
    detectDuplicatesPath: PropTypes.string.isRequired,

    // What URL do we hit to sign up and register this person?
    signUpAndRegisterPath: PropTypes.string.isRequired,
    signInPath: PropTypes.string.isRequired,
    registerPath: PropTypes.string.isRequired,
    sendPasswordResetEmailPath: PropTypes.string.isRequired,

    csrfToken: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    const program = {
      programSequence: {
        higherEdSegment: props?.programData?.higherEdSegment?.toUpperCase(),
      },
      programCustomLabel: props.programData.programCustomLabel,
    };

    const translator = new LabelTranslator(program);

    this.state = {
      currentState: signUpForm, // Which state are we in?
      translator: translator,
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      // GDPR regulations require the next two items.
      isEUCitizen: false,
      acceptedLegalTerms: false,

      // For tracking when we should display a spinner to let the user know we're doing something on
      // their behalf.
      processingSubmission: false,

      // We may display errors to the user if information they provided is invalid.
      errorLegalTerms: false,
      errorEmail: false,
      errorFirstName: false,
      errorLastName: false,
      errorPassword: false,
      errorConfirmPassword: false,
      errors: [],

      // Show/hide for the "additional info" box at the bottom of the screen.
      displayingAdditionalInfo: false,

      // Has this user been through the disambiguation flow? May be null, or strings yesThatsMe or
      // noThatsNotMe (defined at the top of this file).
      disambiguationOutcome: null,
      // What the backend has told us about the other user who might be this one (if applicable).
      existingUserData: null,
    };
  }

  render() {
    return (
      <StyletronProvider value={engine}>
        <BaseProvider theme={baseuiTheme}>
          <ThemeProvider theme={{ ...deprecatedTheme, ...baseuiTheme }}>
            <div className="sign-up-flow">{this.renderForCurrentState()}</div>
          </ThemeProvider>
        </BaseProvider>
      </StyletronProvider>
    );
  }

  renderForCurrentState() {
    switch (this.state.currentState) {
      case signUpForm: {
        let signUpAdditionalInfo;
        if (this.state.displayingAdditionalInfo) {
          signUpAdditionalInfo = (
            <SignUpAdditionalInfo role={this.props.role} translator={this.state.translator} />
          );
        }

        return (
          <LoginBackground>
            <StyledCard>
              <ProgramSchoolLogoBlock
                programLogo={this.props.programData.logo}
                programName={this.props.programData.title}
                schoolLogo={this.props.programData.schoolLogo}
                schoolName={this.props.programData.schoolName}
              />
              {this.renderSignUpForm()}
              <div>
                <div className="text-align-center">
                  <StyledParagraphSmall>
                    Not sure what's going on?{" "}
                    <StyledLink
                      data-test="additional-info-btn"
                      tabIndex="0"
                      onClick={() =>
                        this.setState({
                          displayingAdditionalInfo: !this.state.displayingAdditionalInfo,
                        })
                      }
                    >
                      Learn More
                    </StyledLink>
                  </StyledParagraphSmall>
                </div>
                {signUpAdditionalInfo}
              </div>
            </StyledCard>
          </LoginBackground>
        );
      }
      case disambiguating: {
        return (
          <div>
            <div className="brand-name text-align-center">
              <ProgramSchoolLogoBlock
                programLogo={this.props.programData.logo}
                programName={this.props.programData.title}
                schoolLogo={this.props.programData.schoolLogo}
                schoolName={this.props.programData.schoolName}
              />
            </div>
            <DisambiguateDuringSignUp
              role={this.props.role}
              schoolName={this.props.programData.schoolShortName}
              email={this.state.email}
              firstName={this.state.firstName}
              lastName={this.state.lastName}
              existingUserData={this.state.existingUserData}
              onNoThatsNotMeClicked={this.onNoThatsNotMeClicked}
              onYesThatsMeClicked={this.onYesThatsMeClicked}
            />
          </div>
        );
      }
      case existingUserSignInForm: {
        return (
          <div>
            <div className="brand-name text-align-center">
              <ProgramSchoolLogoBlock
                programLogo={this.props.programData.logo}
                programName={this.props.programData.title}
                schoolLogo={this.props.programData.schoolLogo}
                schoolName={this.props.programData.schoolName}
              />
            </div>
            <ExistingUserSignInForm
              roleToRegisterFor={this.props.role}
              programId={this.props.programData.id}
              knownUserInfo={
                this.state.existingUserData
                  ? { ...this.state.existingUserData }
                  : { email: this.state.email }
              }
              inputUserInfo={{
                firstName: this.state.firstName,
                lastName: this.state.lastName,
              }}
              signInPath={this.props.signInPath}
              registerPath={this.props.registerPath}
              sendPasswordResetEmailPath={this.props.sendPasswordResetEmailPath}
              csrfToken={this.props.csrfToken}
            />
          </div>
        );
      }
    }
  }

  onNoThatsNotMeClicked = () => {
    // Change state so the form appears again, then submit it. We pass a callback to setState which
    // fires once the form has been rendered.
    this.setState(
      { currentState: signUpForm, disambiguationOutcome: noThatsNotMe },
      this.signUpAndRegister,
    );
  };

  onYesThatsMeClicked = () => {
    this.setState({ disambiguationOutcome: yesThatsMe });

    // The user has indicated that they have an existing account. The account may be already signed
    // up (have a password), or it may not.
    if (this.state.existingUserData.isSignedUp) {
      // The other account is signed up, so the user needs to sign into it. Clear the data you've
      // typed in so far.
      this.setState({
        currentState: existingUserSignInForm,
        email: "",
        password: "",
      });
      return;
    }

    // Otherwise, we can just go ahead and sign them up ourselves.

    // Change state so the form appears again, then submit it. We pass a callback to setState which
    // fires once the form has been rendered.
    this.setState({ currentState: signUpForm }, () =>
      this.signUpAndRegister(this.state.existingUserData.id),
    );
  };

  renderSignUpForm() {
    let text;
    switch (this.props.role) {
      case "Student":
        text = this.state.translator.transformText("Sign Up to Get a Mentor");
        break;
      case "Mentor":
        text = this.state.translator.transformText("Sign Up to Be a Mentor");
        break;
    }

    return (
      <div>
        <div>
          <StyledHeading>{text}</StyledHeading>
          {this.renderMessageSection()}
        </div>
        {this.props.userId
          ? this.renderLoggedInUserRegistration()
          : this.renderNotLoggedInUserRegistration()}
      </div>
    );
  }

  renderLoggedInUserRegistration() {
    const ssoText = `Continue With ${
      this.props.ssoBrand ? this.props.ssoBrand : "Institutional Credentials"
    }`;
    return (
      <Fragment>
        {this.props.isSsoEnabled ? (
          <Fragment>
            {this.props.activeSsoTokenForSchool && (
              <form name="sign_up_form" onSubmit={this.onLoggedInUserSubmitSignUp}>
                <ButtonContainer>
                  <Button
                    className="ssoButton"
                    isStretched={true}
                    leftIcon={<Enterprise size={32} />}
                    dataAnalyticsId="continue-with-sso-logged-in-user"
                  >
                    {ssoText}
                  </Button>
                </ButtonContainer>
              </form>
            )}
            {!this.props.activeSsoTokenForSchool && (
              <ButtonContainer>
                <SsoButton
                  csrfToken={this.props.csrfToken}
                  schoolId={this.props.programData.schoolId}
                  connection={this.props.ssoConnection}
                  buttonText={ssoText}
                  dataAnalyticsId="continue-with-sso-logged-in-user"
                />
              </ButtonContainer>
            )}
          </Fragment>
        ) : (
          <form name="sign_up_form" onSubmit={this.onLoggedInUserSubmitSignUp}>
            <ButtonContainer>
              <Button
                className="signupButton"
                data-analytics-id="signup-mc-registration-logged-in-user"
                isStretched={true}
              >
                Sign Up
              </Button>
            </ButtonContainer>
          </form>
        )}
      </Fragment>
    );
  }

  renderNotLoggedInUserRegistration() {
    const queryParams = {
      is_eu: this.state.isEUCitizen,
      accepted_terms: this.state.acceptedLegalTerms,
    };

    return (
      <Fragment>
        {this.props.isSsoEnabled && (
          <div>
            <CheckboxContainer>
              <LegalTermsAgreement
                acceptedLegalTerms={this.state.acceptedLegalTerms}
                hasError={this.state.errorLegalTerms}
                onAgreementStatusChanged={(agreementStatus) =>
                  this.setState({ acceptedLegalTerms: agreementStatus, errorLegalTerms: false })
                }
              />
              <EuCitizenCheckbox
                schoolId={this.props.programData.schoolId}
                euCitizen={this.state.isEUCitizen}
                onChange={(event) => this.onFormDataChanged("isEUCitizen", event.target.checked)}
              />
            </CheckboxContainer>
            <ButtonContainer>
              <SsoButton
                csrfToken={this.props.csrfToken}
                dataAnalyticsId="continue-with-sso-not-logged-in-user"
                className="vertical-spacing two-rems text-align-center"
                schoolId={this.props.programData.schoolId}
                connection={this.props.ssoConnection}
                onSubmit={this.handleSsoValidate}
                queryParams={queryParams}
                buttonText={`Continue With ${
                  this.props.ssoBrand ? this.props.ssoBrand : "Institutional Credentials"
                }`}
              />
            </ButtonContainer>
          </div>
        )}
        {!this.props.isSsoEnabled && this.renderFormElement()}
        {this.renderFormErrorsElement()}
      </Fragment>
    );
  }

  renderMessageSection() {
    return (
      <Fragment>
        {this.props.userId ? (
          <Fragment>
            <StyledParagraphMedium>
              Welcome back,{" "}
              <span className="styled-highlighted-paragraph">{this.props.userFullName}</span>!
              {this.props.isSsoEnabled &&
                ` This program requires you to sign up using ${
                  this.props.ssoBrand ? this.props.ssoBrand : "Institutional Credentials"
                }.`}{" "}
              Please follow the next few steps to get you registered for{" "}
              <span className="styled-highlighted-paragraph">
                {this.props.programData.shortName} {this.props.programData.title}
              </span>{" "}
              at {this.props.programData.schoolName}.
            </StyledParagraphMedium>
            <StyledParagraphMedium>
              Not your account?{" "}
              <StyledLink tabIndex="0" onClick={this.signOut} data-test={"registration-sign-out"}>
                Sign out
              </StyledLink>
            </StyledParagraphMedium>
          </Fragment>
        ) : (
          <StyledParagraphMedium>
            Welcome! Please sign up to join the{" "}
            <span className="styled-highlighted-paragraph">
              {this.props.programData.shortName} {this.props.programData.title}
            </span>{" "}
            at {this.props.programData.schoolName}.
          </StyledParagraphMedium>
        )}
      </Fragment>
    );
  }

  renderFormElement() {
    // We aren't using the submit capabilities of the <form> element -- that is handled by Ajax.
    return (
      <form name="sign_up_form" onSubmit={this.onSubmitSignUpForm}>
        {this.renderFormItems()}
        <CheckboxContainer>
          <LegalTermsAgreement
            acceptedLegalTerms={this.state.acceptedLegalTerms}
            hasError={this.state.errorLegalTerms}
            onAgreementStatusChanged={(agreementStatus) =>
              this.setState({ acceptedLegalTerms: agreementStatus, errorLegalTerms: false })
            }
          />
          <EuCitizenCheckbox
            schoolId={this.props.programData.schoolId}
            euCitizen={this.state.isEUCitizen}
            onChange={(event) => this.onFormDataChanged("isEUCitizen", event.target.checked)}
          />
        </CheckboxContainer>
        {this.state.processingSubmission && <CenteredLoadingSpinner />}
        <ButtonContainer>
          <Button
            className="signupButton"
            data-analytics-id="signup-mc-registration-not-logged-in-user"
            isStretched={true}
            data-test={"registration-sign-up-submit-button"}
          >
            Sign Up
          </Button>
        </ButtonContainer>
      </form>
    );
  }

  renderFormItems() {
    return (
      <div className="styled-inputs">
        <FormControl
          label="Email"
          error={this.state.errorEmail}
          caption={
            this.state.errorEmail
              ? this.state.email.trim() === ""
                ? "Email is required."
                : "Invalid email format."
              : ""
          }
        >
          <Input
            aria-label="email"
            name="email"
            value={this.state.email}
            placeholder="Enter your email"
            onChange={this.onEmailChanged}
            dataTest={"registration-email-input"}
          />
        </FormControl>
        <FormControl
          label="First Name"
          error={this.state.errorFirstName}
          caption={this.state.errorFirstName ? "First name is required." : ""}
        >
          <Input
            aria-label="first name"
            name="first_name"
            value={this.state.firstName}
            placeholder="Enter your first name"
            onChange={(event) => {
              this.onFormDataChanged("firstName", event.target.value);
              this.setState({ errorFirstName: false });
            }}
            dataTest={"registration-first-name-input"}
          />
        </FormControl>
        <FormControl
          label="Last Name"
          error={this.state.errorLastName}
          caption={this.state.errorLastName ? "Last name is required." : ""}
        >
          <Input
            aria-label="last name"
            name="last_name"
            value={this.state.lastName}
            placeholder="Enter your last name"
            onChange={(event) => {
              this.onFormDataChanged("lastName", event.target.value);
              this.setState({ errorLastName: false });
            }}
            dataTest={"registration-last-name-input"}
          />
        </FormControl>
        <FormControl
          label="Password"
          caption={
            this.state.errorPassword && this.state.password.trim() === ""
              ? "Password is required."
              : "Must be 10 or more characters and contain at least 1 uppercase character, 1 number, and 1 special character."
          }
          error={this.state.errorPassword}
        >
          <Input
            aria-label="password"
            type="password"
            name="password"
            value={this.state.password}
            placeholder="Enter your password"
            onChange={this.onPasswordChanged}
            dataTest={"registration-password-input"}
          />
        </FormControl>
        <FormControl
          label="Confirm Password"
          error={this.state.errorConfirmPassword}
          caption={
            this.state.errorConfirmPassword
              ? this.state.confirmPassword.trim() === ""
                ? "Confirm password is required."
                : "Passwords do not match."
              : ""
          }
        >
          <Input
            aria-label="confirm password"
            type="password"
            name="confirm_password"
            value={this.state.confirmPassword}
            onChange={(event) => {
              this.onFormDataChanged("confirmPassword", event.target.value);
              this.setState({ errorConfirmPassword: false });
            }}
            dataTest={"registration-confirm-password-input"}
          />
        </FormControl>
      </div>
    );
  }

  onEmailChanged = (event) => {
    const email = event.target.value;

    this.setState({ errorEmail: false });
    this.onFormDataChanged("email", email);
    this.checkIfSignedUp(email);
  };

  onPasswordChanged = (event) => {
    this.setState({ errorPassword: false });
    this.onFormDataChanged("password", event.target.value);
  };

  // We don't want to overwhelm the backend with requests, so we debounce it to ensure that the
  // check is only made 300 milliseconds after the user stops typing.
  checkIfSignedUp = _.debounce((email) => {
    // If we're in the signing-in-to-an-existing-user stage, don't check -- we don't want to update
    // the state no matter what the email changes to.
    if (this.state.existingUserData && this.state.existingUserData.isSignedUp) {
      return;
    }

    jQuery.getJSON(this.props.checkIfUserSignedUpPath, { email }).then((response) => {
      this.setState({ currentState: response.signed_up ? existingUserSignInForm : signUpForm });
    });
  }, 300);

  onFormDataChanged(key, newValue) {
    // Whenever form data changes, we want to reset the errors attribute as they may no longer be
    // relevant. (There might still be errors, of course, but we want to be optimistic. We'll
    // discover them again when the user presses submit.)
    const update = { errors: [] };
    update[key] = newValue;

    this.setState(update);
  }

  renderFormErrorsElement() {
    if (this.state.errors.length === 0) {
      return null;
    }

    const errorItems = this.state.errors.map((error) => (
      <div key={error} className="error-item">
        {error}
      </div>
    ));

    return <div className="form-errors">{errorItems}</div>;
  }

  handleSsoValidate = (e) => {
    let validForSso = true;

    if (this.state.acceptedLegalTerms === false) {
      e.preventDefault();
      this.setState({ errorLegalTerms: true });
      validForSso = false;
    }

    return validForSso;
  };

  /**
   * Checks to make sure the information is valid, before allowing submission to proceed. Also
   * populates this.state.errors if it returns false.
   * @return {Boolean} True if we allow submission to proceed, false otherwise.
   */
  validateBeforeSubmission() {
    let errorsDetected = false;

    // no email
    if (this.state.email.trim() === "") {
      this.setState({ errorEmail: true });
      errorsDetected = true;
    }

    // invalid email format
    if (!this.state.email.match(emailRegex)) {
      this.setState({ errorEmail: true });
      errorsDetected = true;
    }

    // no first name
    if (this.state.firstName.trim() === "") {
      this.setState({ errorFirstName: true });
      errorsDetected = true;
    }

    // no last name
    if (this.state.lastName.trim() === "") {
      this.setState({ errorLastName: true });
      errorsDetected = true;
    }

    // password does not meet requirements
    if (!isValidPassword(this.state.password)) {
      this.setState({ errorPassword: true });
      errorsDetected = true;
    }

    // password confirm is null or does not match password
    if (
      this.state.confirmPassword.trim() === "" ||
      this.state.password !== this.state.confirmPassword
    ) {
      this.setState({ errorConfirmPassword: true });
      errorsDetected = true;
    }

    // legal terms were not accepted
    if (this.state.acceptedLegalTerms === false) {
      this.setState({ errorLegalTerms: true });
      errorsDetected = true;
    }

    return !errorsDetected;
  }

  signOut = async () => {
    await fetch("/api/users/sign_out", {
      method: "DELETE",
      headers: {
        "X-CSRF-Param": "authenticity_token",
        "X-CSRF-Token": `${this.props.csrfToken}`,
      },
    }).then(() => {
      window.location.reload();
    });
  };

  onLoggedInUserSubmitSignUp = async (event) => {
    event.preventDefault();
    try {
      await jQuery.post({
        url: this.props.registerPath,
        headers: { "X-CSRF-Token": this.props.csrfToken },
        data: {
          role: this.props.role,
          program_id: this.props.programData.id,
        },
      });
    } catch (response) {
      // Display the error in the form.
      this.setState({ errors: response.responseJSON.errors });
      return;
    } finally {
      this.setState({ processingSubmission: false });
    }

    // We're now signed in. Reload the page to show the user the next step in the pre-match flow.
    window.location.reload();
  };

  onSubmitSignUpForm = async (event) => {
    // We don't actually want the form to be submitted. Instead we take data from the form and
    // submit it using AJAX.
    event.preventDefault();

    const validationPassed = this.validateBeforeSubmission();
    if (!validationPassed) {
      return;
    }

    // If we've already been through the disambiguation flow, just submit the form normally.
    if (this.state.disambiguationOutcome !== null) {
      return this.signUpAndRegister();
    }

    // Start the button spinning so the user knows we're doing something.
    this.setState({ processingSubmission: true });

    // Check to see if there are any existing contact users (not signed up) with the same name and email
    try {
      const data = {
        program_id: this.props.programData.id,
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
      };
      const response = await jQuery.getJSON(this.props.detectExistingContactPath, data);
      const matchingUserFromContact = Utils.withKeysCamelized(response.data).matchingUser;

      if (matchingUserFromContact !== null && !matchingUserFromContact.signedUp) {
        // If the call to the backend revealed that there's another user matching these details,
        // proceed to sign up with that information.
        return this.signUpAndRegister(matchingUserFromContact.id);
      }
    } catch (response) {
      // Do nothing: we'll notice the error on the backend, and we just want registration to proceed.
    }

    // Check to see if there are any existing users with the same name.
    try {
      const data = {
        program_id: this.props.programData.id,
        first_name: this.state.firstName,
        last_name: this.state.lastName,
      };
      const response = await jQuery.getJSON(this.props.detectDuplicatesPath, data);
      const matchingUserData = Utils.withKeysCamelized(response.data).matchingUser;
      if (matchingUserData !== null) {
        // If the call to the backend revealed that there's another user matching these details,
        // switch into the disambiguation flow.
        this.setState({ currentState: disambiguating, existingUserData: matchingUserData });
        return;
      }
    } catch (response) {
      // Do nothing: we'll notice the error on the backend, and we just want registration to proceed.
    }

    // Otherwise, proceed with the registration.
    return this.signUpAndRegister();
  };

  async signUpAndRegister(existingUserId) {
    try {
      await jQuery.post({
        url: this.props.signUpAndRegisterPath,
        data: {
          email: this.state.email,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          password: this.state.password,

          is_eu_citizen: this.state.isEUCitizen,
          has_accepted_privacy_policy: this.state.acceptedLegalTerms,

          role: this.props.role,
          program_id: this.props.programData.id,

          // The id of the (not signed-up) User record that we should "absorb" with this signup --
          // if any.
          existing_user_id: existingUserId,
        },
      });
    } catch (response) {
      // Display the error in the form.
      this.setState({ errors: response.responseJSON.errors });
      return;
    } finally {
      this.setState({ processingSubmission: false });
    }

    // We're now signed in. Reload the page to show the user the next step in the pre-match flow.
    window.location.reload();
  }
}

export default SignUpFlow;
