// Our imports
import { SilentH1 } from "../../utils/headers";

// Third-party imports.
import React from "react";
import PropTypes from "prop-types";
import "./disambiguate_during_sign_up.css";
import { Button } from "../components/base_ui/inputs/button";
import { ParagraphMedium } from "baseui/typography";

class DisambiguateDuringSignUp extends React.Component {
  static propTypes = {
    schoolName: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,

    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,

    existingUserData: PropTypes.object,

    onYesThatsMeClicked: PropTypes.func.isRequired,
    onNoThatsNotMeClicked: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className="disambiguate-during-sign-up">
        <ParagraphMedium>
          Our records show that somebody with the following contact information was either invited
          to this program by {this.props.schoolName}, or has been part of a Mentor Collective
          program in the past.
        </ParagraphMedium>

        {this.props.existingUserData && this.props.existingUserData?.redactedEmail && (
          <ParagraphMedium data-test="redacted-email">
            Email: {this.props.existingUserData.redactedEmail}
          </ParagraphMedium>
        )}

        {this.props.existingUserData && this.props.existingUserData?.redactedPhone && (
          <ParagraphMedium data-test="redacted-phone">
            Phone: {this.props.existingUserData.redactedPhone}
          </ParagraphMedium>
        )}

        <SilentH1>
          <b>Is this you?</b>
        </SilentH1>

        <div>
          <div className="vertical-spacing-top one-rem">
            <Button className="yes-thats-me" onClick={this.onClickedYesThatsMe}>
              Yes, that's me
            </Button>
          </div>

          <div className="vertical-spacing-top one-rem">
            <Button className="no-thats-not-me" onClick={this.onClickedNoThatsNotMe}>
              No, that's not me
            </Button>
          </div>
        </div>
      </div>
    );
  }

  onClickedYesThatsMe = () => {
    this.props.onYesThatsMeClicked();
  };

  onClickedNoThatsNotMe = () => {
    this.props.onNoThatsNotMeClicked();
  };
}

export default DisambiguateDuringSignUp;
