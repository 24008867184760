// Third-party imports.
import React from "react";
import styled from "styled-components";
import { ParagraphSmall } from "baseui/typography";
import { StyledLink } from "baseui/link";

const Heading = styled(ParagraphSmall)`
  margin-top: ${({ theme }) => theme.sizing.scale400};
  margin-bottom: ${({ theme }) => theme.sizing.scale300};
  font-weight: ${({ theme }) => theme.typography.HeadingXSmall.fontWeight};
`;

class ExistingUserAdditionalInfo extends React.Component {
  textContainerRef = React.createRef();

  render() {
    return (
      <div className="additional-info" ref={this.textContainerRef}>
        <ParagraphSmall>
          Since you already have a mentor or mentee account with us, it's important that we link
          your new signup rather than create a new account. If you have multiple emails, make sure
          you enter the one with the domain outlined above (in bold).
        </ParagraphSmall>
        <Heading>Still can't log in?</Heading>
        <ParagraphSmall>
          Click "Forgot password?" and we'll send you a link to reset it. For any further questions,
          please write to{" "}
          <StyledLink href="mailto:help@mentorcollective.org">help@mentorcollective.org</StyledLink>{" "}
          or hit the "back" button on your browser and choose "I'm not sure" when asked "Is this
          you?" and a team member will reach out within 1 business day.
        </ParagraphSmall>
      </div>
    );
  }

  componentDidMount() {
    if (this.textContainerRef?.current?.scrollIntoView) {
      this.textContainerRef.current.scrollIntoView();
    }
  }
}

export default ExistingUserAdditionalInfo;
