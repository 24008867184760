// Third-party imports.
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ParagraphSmall } from "baseui/typography";
import { NewTab } from "@carbon/icons-react";
import { StyledLink } from "baseui/link";

const Heading = styled(ParagraphSmall)`
  margin-top: ${({ theme }) => theme.sizing.scale400};
  margin-bottom: 0px;
  font-weight: ${({ theme }) => theme.typography.HeadingXSmall.fontWeight};
`;

const StyledParagraphSmall = styled(ParagraphSmall)`
  margin-top: ${({ theme }) => theme.sizing.scale800};
`;

const StyledLinksContainer = styled(ParagraphSmall)`
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-gap: 5px;
  margin-top: ${({ theme }) => theme.sizing.scale300};
  margin-bottom: 0px;
  color: ${({ theme }) => theme.colors.primary500};
`;
class SignUpAdditionalInfo extends React.Component {
  textContainerRef = React.createRef();

  static propTypes = {
    role: PropTypes.string.isRequired, // 'Student' or 'Mentor'
    translator: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div ref={this.textContainerRef}>
        {this.renderFirstParagraph()}
        <StyledParagraphSmall>
          {this.props.translator.transformText(
            "The next few steps will ensure that you have the information to succeed in your mentorship.",
          )}
        </StyledParagraphSmall>
        <Heading>Still confused?</Heading>
        <StyledLinksContainer>
          <NewTab size={20} />
          <StyledLink
            className="program-info-url-link"
            aria-label={`${this.getProgramInfoLinkText()}, opens in a new tab`}
            data-test="program-info-url-link"
            href={this.customOrDefaultProgramInfoUrl()}
            target="_blank"
          >
            {this.getProgramInfoLinkText()}
          </StyledLink>
        </StyledLinksContainer>
        <StyledLinksContainer>
          <NewTab size={20} />
          <StyledLink
            aria-label="Send an email to help@mentorcollective.org to ask individual questions, opens in a new tab"
            href="mailto:help@mentorcollective.org"
            target="_blank"
          >
            You can also email us at help@mentorcollective.org to ask individual questions
          </StyledLink>
        </StyledLinksContainer>
      </div>
    );
  }

  renderFirstParagraph() {
    const match = this.props.role === "Student" ? "mentor" : "mentee";

    return (
      <StyledParagraphSmall>
        Mentor Collective is an international mentoring community that partners with institutions
        like yours to deliver a transformative mentorship experience. You're in the process of being
        matched with a {this.props.translator.transformText(match)} in your institution's program.
      </StyledParagraphSmall>
    );
  }

  customOrDefaultProgramInfoUrl() {
    // These are URLs on our partner-facing website.
    if (this.props.role === "Student") {
      return "https://www.mentorcollective.org/mentees";
    } else if (this.props.role === "Mentor") {
      return "https://www.mentorcollective.org/mentors";
    }
  }

  getProgramInfoLinkText() {
    const role = this.props.role === "Mentor" ? "mentor" : "mentee";
    return this.props.translator.transformText(
      `Head over to the Mentor Collective website to learn more about becoming a ${role}`,
    );
  }

  componentDidMount() {
    if (this.textContainerRef?.current?.scrollIntoView) {
      this.textContainerRef.current.scrollIntoView();
    }
  }
}

export default SignUpAdditionalInfo;
