import React, { useState } from "react";
import jQuery from "jquery";
import { ParagraphMedium } from "baseui/typography";
import { CenteredLoadingSpinner } from "../../../components/base_ui/progress_and_validation/spinner/centered_loading_spinner";
import { Button } from "../../../components/base_ui/inputs/button";
import FormControl from "../../../components/base_ui/inputs/form_control";
import Input from "../../../components/base_ui/inputs/input";

export async function sendRecoverySMS(
  userId: string,
  programId: string,
  firstName: string,
  lastName: string
): Promise<void> {
  try {
    const result = await jQuery.post({
      url: "/api/users/send_sms_recovery_code",
      data: {
        user_id: userId,
        program_id: programId,
        first_name: firstName,
        last_name: lastName,
      },
    });
    if (!result.sms_sent) {
      throw new Error("Something went wrong. SMS not sent");
    }
  } catch (response) {
    throw new Error("Something went wrong trying to send an account recovery SMS");
  }
}

export default function ResetWithSms({
  onClickBack,
  userId,
  programId,
  roleToRegisterFor,
}: {
  onClickBack: () => void;
  userId: string;
  programId: string;
  roleToRegisterFor: string;
}) {
  const [inputRecoveryCode, setInputRecoveryCode] = useState<string>("");
  const [checkingCode, setCheckingCode] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  return (
    <div className="text-align-center vertical-spacing-top two-rems">
      <ParagraphMedium>
        Great! We just sent a recovery code to the phone number you have registered to your existing
        account. Once you receive it, please enter it below.
      </ParagraphMedium>
      <div className="vertical-spacing-top two-rems">
        <FormControl
          label="Recovery Code"
          overrides={{
            Label: {
              style: ({ $theme }) => ({
                fontFamily: $theme.typography.LabelMedium.fontFamily,
                fontWeight: $theme.typography.LabelMedium.fontWeight,
                fontSize: $theme.typography.LabelMedium.fontSize,
                lineHeight: $theme.typography.LabelMedium.lineHeight,
                textTransform: "none",
              }),
            },
          }}
        >
          <Input
            value={inputRecoveryCode}
            placeholder="999999"
            onChange={({ currentTarget: { value: updatedCode } }) =>
              setInputRecoveryCode(updatedCode)
            }
          />
        </FormControl>
      </div>
      {error && (
        <div className="form-errors">
          <div className="error-item">{error}</div>
        </div>
      )}
      <div className="vertical-spacing-top two-rems">
        <Button
          onClick={async () => {
            setCheckingCode(true);
            try {
              const result = await jQuery.post({
                url: "/api/users/validate_recovery_code",
                data: {
                  user_id: userId,
                  program_id: programId,
                  recovery_code: inputRecoveryCode,
                  role: roleToRegisterFor,
                },
              });
              if (!result.validated) {
                setError(
                  "Invalid code. Please double check your phone and try again. If you're still having problems, go back to the previous page and try again."
                );
              } else if (result.recovery_link) {
                window.location.replace(result.recovery_link);
              }
            } catch (response) {
              setError("There was a problem sending your code. Please reload and try again.");
            } finally {
              setCheckingCode(false);
            }
          }}
        >
          Sign in
        </Button>
      </div>
      <div className="vertical-spacing-top two-rems">
        {checkingCode && <CenteredLoadingSpinner />}
        <Button onClick={() => onClickBack()}>Back</Button>
      </div>
    </div>
  );
}
