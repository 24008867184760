import React from "react";
import PropTypes from "prop-types";
import ResetWithEmail from "./email";
import ResetWithSms, { sendRecoverySMS } from "./sms";
import { ParagraphMedium } from "baseui/typography";
import { CenteredLoadingSpinner } from "../../../components/base_ui/progress_and_validation/spinner/centered_loading_spinner";
import { Button } from "../../../components/base_ui/inputs/button";

const email = "email";
const sms = "sms";

export default class ExistingUserResetPassword extends React.Component {
  static propTypes = {
    // What do we already know about this existing user? We should either have
    // the email that a user entered and we validated as an existing account,
    // or if we instead identified them based on first/last name, the domain
    // of the email they already have registered (we don't know 100% if this is actually
    // the current user or not yet, so we don't have the full email), and whether or not
    // the already registered user has a valid phone #
    knownUserInfo: PropTypes.oneOfType([
      PropTypes.shape({
        emailDomain: PropTypes.string.isRequired,
        hasValidPhone: PropTypes.bool.isRequired,
        id: PropTypes.number.isRequired,
      }),
      PropTypes.shape({
        email: PropTypes.string.isRequired,
      }),
    ]),
    inputUserInfo: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
    sendPasswordResetEmailPath: PropTypes.string.isRequired,
    programId: PropTypes.string.isRequired,
    roleToRegisterFor: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      recoveryType: props.knownUserInfo.email ? props.knownUserInfo.email : undefined,
      isSendingSMS: false,
      error: undefined,
    };
  }

  render() {
    return !this.props.knownUserInfo.hasValidPhone || this.state.recoveryType === email ? (
      <ResetWithEmail
        knownUserInfo={this.props.knownUserInfo}
        sendPasswordResetEmailPath={this.props.sendPasswordResetEmailPath}
        programId={this.props.programId}
        roleToRegisterFor={this.props.roleToRegisterFor}
        onClickBack={() =>
          this.setState((prevState) => ({ ...prevState, recoveryType: undefined }))
        }
      />
    ) : this.state.recoveryType === sms ? (
      <ResetWithSms
        onClickBack={() =>
          this.setState((prevState) => ({ ...prevState, recoveryType: undefined }))
        }
        userId={this.props.knownUserInfo.id}
        programId={this.props.programId}
        roleToRegisterFor={this.props.roleToRegisterFor}
      />
    ) : (
      <div className="text-align-center">
        <div className="text-align-center vertical-spacing-top two-rems">
          <ParagraphMedium>
            Looks like you have both an e-mail address and phone number associated with your
            existing account. To reset your password, we can send a code to either one. How would
            you like to receive your recovery code?
          </ParagraphMedium>
        </div>
        {this.state.error && (
          <div className="form-errors">
            <div className="error-item">{this.state.error}</div>
          </div>
        )}
        <div className="vertical-spacing-top two-rems">
          <Button
            onClick={() => {
              this.setState((prevState) => ({ ...prevState, recoveryType: email }));
            }}
          >
            Reset with Email
          </Button>
        </div>
        <div className="vertical-spacing-top two-rems">
          {this.state.isSendingSMS && <CenteredLoadingSpinner />}
          <Button
            onClick={async () => {
              this.setState((prevState) => ({ ...prevState, isSendingSMS: true }));
              try {
                await sendRecoverySMS(
                  this.props.knownUserInfo.id,
                  this.props.programId,
                  this.props.inputUserInfo.firstName,
                  this.props.inputUserInfo.lastName
                );
              } catch (error) {
                this.setState((prevState) => ({
                  ...prevState,
                  isSendingSMS: false,
                  error:
                    "Something went wrong trying to send a SMS recovery code. Please reload the page and try again.",
                }));
              }
              this.setState((prevState) => ({
                ...prevState,
                isSendingSMS: false,
                recoveryType: sms,
              }));
            }}
          >
            Reset with SMS
          </Button>
        </div>
      </div>
    );
  }
}
